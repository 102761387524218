<template>
    <div>                 
        <div class="box is-hero-bar is-main-hero column is-12" v-if="avisos != null && avisos.length > 0">        
            <div class="panel">
                <div v-for="(aviso, index) in avisos" :key="index">
                    <div class="panel-block columns is-multiline">                        
                        <div class="column is-2">
                            <span v-if="aviso.guiaId != 0">
                                <small>Pedido:</small>
                                <br>                            
                                <router-link :to="{ name: 'portalconvenioguia', params: { id: aviso.guiaId }}">
                                    <b>{{aviso.guiaId}}</b>
                                </router-link>
                            </span>
                            <span v-else>
                                <small>Amostra:</small>
                                <br>
                                <b>{{aviso.codigoDeBarras}}</b>
                            </span>
                        </div>                      
                        <div class="column is-1">
                            <small>Tipo:</small>
                            <br>
                            <b v-if="aviso.status != 0">{{aviso.status}}</b>
                            <b v-else>{{aviso.statusAmostra}}</b>
                        </div>                    
                        <div class="column is-9 is-full-mobile">
                            <small>Exames:</small>  
                            <br>                          
                            <b-tag v-for="(exameApelido, j) in aviso.examesApelidos" v-bind:key="j"
                                :type="'default'">
                                {{ exameApelido }}
                            </b-tag>
                        </div>               
                        <div class="column is-12">
                            <span>{{aviso.descricaoExterno}}</span>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapState } from 'vuex'

    export default {

        data() {
            return {
                avisos: []
            };
        },
        computed: {            
            ...mapState([
                'config',
                'usuario'
            ])
        },
        
        methods:{
            carregarAvisos(){
                if(this.avisosCount != 0){
                    this.$http.get('/api/portal/Avisos')
                        .then(res => {
                            this.avisos = res.data;
                        });
                }
            },            
        },
        created(){
            this.carregarAvisos();
        },        
    }

</script>