<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <titlebar :title-stack="titleStack"> </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box" v-if="notaFiscalConsulta">
                            <p>{{ notaFiscal.mensagem }}</p>
                            <div class="columns">
                                <div class="column">
                                    <div>
                                        <p class="heading">Documento</p>
                                        <b>{{ notaFiscal.idIntegracao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Prestador</p>
                                        <b>{{ notaFiscal.prestador }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Tomador</p>
                                        <b>{{ notaFiscal.tomador }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Valor</p>
                                        <b>{{ notaFiscal.valorServico | currency }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Situação</p>
                                        <b>{{ notaFiscal.situacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Identificação da Nota Fiscal</p>
                                        <b>{{ notaFiscal.id }}</b>
                                    </div>
                                </div>
                            </div>
                            <div v-if="notaFiscal.situacao === 'CONCLUIDO'" class="columns">
                                <div class="column">
                                    <div>
                                        <p class="heading">Número NFSE</p>
                                        <b>{{ notaFiscal.numeroNfse }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Série</p>
                                        <b>{{ notaFiscal.serie }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Lote</p>
                                        <b>{{ notaFiscal.lote }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Número</p>
                                        <b>{{ notaFiscal.numero }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Código Verificação</p>
                                        <b>{{ notaFiscal.codigoVerificacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Autorização</p>
                                        <b>{{ notaFiscal.autorizacao }}</b>
                                    </div>
                                </div>
                                <div class="column">
                                    <div>
                                        <p class="heading">Mensagem</p>
                                        <b>{{ notaFiscal.mensagem }}</b>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <b-field label="Conta Corrente">
                                        <b-select placeholder=""
                                                  v-model="model.contaCorrente.id"
                                                  expanded>
                                            <option v-for="option in contas"
                                                    :value="option.value"
                                                    :key="option.value">
                                                {{ option.text }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="Data">
                                        <b-datetimepicker v-model="model.data"
                                            v-if="model.id > 0"
                                            placeholder="Selecione uma Data"
                                            icon="calendar-today"
                                            :timepicker="{ enableSeconds: true, hourFormat: '24' }"
                                            :disabled="model.id > 0"
                                            horizontal-time-picker
                                            expanded>
                                        </b-datetimepicker>
                                        <b-datepicker v-if="model.id === 0"
                                            v-model="model.data"
                                            placeholder="Selecione uma Data"
                                            icon="calendar-today"
                                            horizontal-time-picker
                                            expanded>
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <searchIdName v-if="model.convenio && !isLoading"
                                        :id.sync="model.convenio.id"
                                        table="convenio"
                                        label="Convênio"
                                        :leitura="model.id > 0" />
                                </div>
                                <div class="column is-2-desktop is-one-quarter-mobile">
                                    <b-field label="Nota Fiscal">
                                        <b-input type="text"
                                            v-if="temNotaFiscal && model.id && model.notaFiscal"
                                            v-model="model.notaFiscal"
                                            maxlength="20"
                                            :has-counter="false"
                                            readonly></b-input>
                                        <b-input type="text"
                                            v-else
                                            v-model="model.notaFiscal"
                                            maxlength="20"
                                            :has-counter="false"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="column">
                                <searchIdName v-if="model.paciente && !isLoading"
                                    :id.sync="model.paciente.id"
                                    table="paciente"
                                    label="Paciente"
                                    :leitura="model.id > 0"
                                    @item="pacienteAlterado" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.funcionario && !isLoading"
                                    :id.sync="model.funcionario.id"
                                    table="funcionario"
                                    label="Funcionario"
                                    :leitura="model.funcionario.id" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.localDeOrigem && !isLoading"
                                    :id.sync="model.localDeOrigem.id"
                                    table="local"
                                    label="Local de Origem"
                                    :leitura="model.id > 0" />
                            </div>
                            <div class="column">
                                <searchIdName v-if="model.unidade && !isLoading" 
                                    :id.sync="model.unidade.id"
                                    table="Unidade"
                                    label="Unidade"
                                    :leitura="model.id > 0"/>
                            </div>
                            <div class="column" >
                                <b-field label="Observações">
                                <textarea class="textarea" v-model="model.observacoes" rows="4"></textarea>
                                </b-field>
                            </div>
                        </article>
                    </div>

                    <div class="tile is-parent" v-if="model.quantidadeExames > 0">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'Receber'"
                                :chave="model.id"
                                v-if="model.id">
                            </historico-alteracoes>
                            <article class="panel" v-if="model.quantidadeExames > 0 && model.convenio.tipoConvenio && model.convenio.tipoConvenio.faturaAberta">
                                <div class="panel-block">
                                    <b-button v-if="model.situacaoReceber == 1"
                                            @click.prevent.stop="fecharFatura()"
                                            class="button is-warning is-fullwidth"
                                            icon-left="lock-open-variant-outline">Fechar Fatura?</b-button>
                                    <b-button v-else
                                            class="button is-success is-fullwidth"
                                            icon-left="lock-open-check">Fatura Fechada</b-button>
                                </div>
                            </article>
                            <div class="panel-heading has-text-centered"
                                 v-if="model.convenio && model.convenio.tissVersao && model.id && model.situacaoReceber == 2 && model.quantidadeExames > 0">
                                TISS
                            </div>
                            <div class="panel-block" v-if="model.convenio && model.convenio.tissVersao && model.id && model.situacaoReceber == 2 && model.quantidadeExames > 0">
                                <router-link :to="{ name: 'xmltiss', params: { receberId: model.id, versao: model.convenio.tissVersao,}}" class="button is-primary-fullwidth">
                                    TISS Lote Guias
                                </router-link>
                            </div>
                            <br v-if="model.id" />
                            <div class="panel-heading has-text-centered"
                                 v-if="model.id && model.situacaoReceber == 2 &&model.quantidadeExames > 0">
                                Gerar Documento
                            </div>
                            <div class="panel-block"
                                 v-if="model.id && model.situacaoReceber == 2 && model.quantidadeExames > 0">
                                <b-button icon-left="eye"
                                          class="btn-action is-success center"
                                          @click.prevent.stop="download('ContasReceberPDF', 'pdf')"
                                          expanded>PDF</b-button>
                                <b-button icon-left="eye"
                                          class="btn-action is-primary center"
                                          @click.prevent.stop="download('ContasReceberPDFSintetico', 'pdf')"
                                          expanded>PDF Sintético</b-button>
                                <b-button icon-left="eye"
                                          class="btn-action is-danger center"
                                          @click.prevent.stop="download('ContasReceberXLS', 'xls')"
                                          expanded>XLS</b-button>
                            </div>
                            <br v-if="model.id" />
                            <div class="panel-block" v-if="model.situacaoReceber == 2">
                                <b-button class="btn-action is-primary center"
                                          v-if="!temNotaFiscal && model.id"
                                          @click.prevent.stop="emitirNotaFiscal()"
                                          expanded>Emitir Nota Fiscal</b-button>
                                <b-button class="btn-action is-info center"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="consultarNotaFiscal()"
                                          expanded>Consultar Nota Fiscal</b-button>
                            </div>
                            <div class="panel-block"
                                 v-if="temNotaFiscal && model.id && model.situacaoReceber == 2">
                                <b-button class="btn-action is-warning center"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="pdfNotaFiscal()"
                                          expanded>PDF Nota Fiscal</b-button>
                            </div>
                            <div class="panel-block"
                                 v-if="temNotaFiscal && model.id && model.situacaoReceber == 2">
                                <b-button class="btn-action is-danger center"
                                          icon-left="close-circle"
                                          v-if="temNotaFiscal && model.id"
                                          @click.prevent.stop="cancelarNotaFiscal()"
                                          expanded>Cancelar Nota Fiscal</b-button>
                            </div>
                            <div class="panel-block">
                                <b-button class="btn-action is-danger center"
                                    icon-left="close-circle"
                                    v-if="!model.cancelada && model.id && model.situacaoReceber == 2"
                                    @click.prevent.stop="cancelarFatura()"
                                    expanded>Cancelar Fatura</b-button>
                                <label class="label button is-danger label-parcela-cancelada"
                                    v-if="model.cancelada && model.id && model.situacaoReceber == 2">
                                    Parcela Cancelada
                                </label>
                                <b-button class="btn-action is-light center"
                                    icon-left="cash-multiple"
                                    v-if="exibirParcelarValor() && model.situacaoReceber == 2"
                                    @click.prevent.stop="dividirParcela()"
                                    expanded>Parcelar Valor
                                </b-button>
                            </div>
                            <div class="panel-block">
                                <b-button v-if="descontoConvenioGuia == 0 && desconto == 0" 
                                          class="btn-action is-light center"
                                          icon-left="cash"
                                          @click.prevent.stop="aplicarDesconto()"
                                          expanded>Desconto</b-button>
                                <div v-else class="well well-sm">
                                    Desconto: <b>R$ {{ formatPrice(descontoConvenioGuia || desconto) }}</b>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         v-if="model.convenio && model.convenio.tissVersao"
                         @click="isOpenParcelas = !isOpenParcelas; $forceUpdate();"> Parcelas
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenParcelas"
                                expanded>
                        <div class="tile is-child notification is-danger"
                             v-if="valorNaoBate">
                            <span>
                                A soma total dos valores ({{ somaParcelas() }}) não bate com o
                                valor total dos exames ({{ valorTotalParcelas }}).
                            </span>
                        </div>
                        <br />
                        <b-table :data="model.parcelas"
                                 striped
                                 hoverable
                                 class="fullwidth"
                                 expanded>
                            <template slot-scope="props">
                                <b-table-column field="" label="Id" >
                                    <span class="tag is-light label">{{ labelId(props.row.id) }}</span>
                                </b-table-column>
                                <b-table-column field="" label="Vencimento">
                                    <div class="columns"
                                         v-if=" (props.row.situacao === 1 || props.row.situacao === 5 || props.row.situacao === 6 || props.row.situacao === 7) && isInRole('receber-parcela-alterar-vencimento')">
                                        <div class="column">
                                            <b-datepicker editable
                                                          v-model="props.row.dataDeVencimento"
                                                          readonly
                                                          icon="calendar-today"
                                                          :input="dataDeVencimentoAlterada(props.row)"
                                                          horizontal-date-picker
                                                          expanded>
                                            </b-datepicker>
                                        </div>
                                        <div v-if="props.row.vencimentoAlterado && props.row.id !== 0"
                                             class="column is-6">
                                            <b-select required
                                                      placeholder="Selecione o Motivo"
                                                      class="is-fullwidth"
                                                      v-model="props.row.motivoVencimentoAlterado">
                                                <option v-for="item in motivosVencimentoAlterado"
                                                        :key="item.id"
                                                        :value="item.id">
                                                    {{ item.descricaoInterno }}
                                                </option>
                                            </b-select>
                                        </div>
                                    </div>
                                    <label class="label"
                                        v-if=" (props.row.situacao !== 1 && props.row.situacao !== 5 && props.row.situacao !== 6 && props.row.situacao !== 7) || !isInRole('receber-parcela-alterar-vencimento')">
                                        {{ props.row.dataDeVencimento | data }}
                                    </label>
                                </b-table-column>
                                <b-table-column field="" label="Valor">
                                    <div class="columns"
                                         v-if="props.row.situacao === 1 && isInRole('receber-parcela-alterar-valor')">
                                        <div class="column">
                                            <b-field>
                                                <p class="control">
                                                    <button class="button is-light" type="button">
                                                        R$
                                                    </button>
                                                </p>
                                                <input class="input"
                                                    type="number"
                                                    v-if="qntdeParcelas > 1 || model.quantidadeExames == 0"
                                                    @blur="recalcularParcelas(props.row, props.index) && validarValorTotalDasParcelas()"
                                                    step=".00000001"
                                                    v-model="props.row.valor" 
                                                />
                                                 <input class="input"
                                                    v-else
                                                    type="number"
                                                    disabled
                                                    @blur="recalcularParcelas(props.row, props.index) && validarValorTotalDasParcelas()"
                                                    step=".00000001"
                                                    v-model="props.row.valor" 
                                                />
                                            </b-field>
                                        </div>
                                        <div v-if="props.row.descontoAplicado" class="column is-6">
                                            <b-select required
                                                      placeholder="Selecione o Motivo"
                                                      class="is-fullwidth"
                                                      v-model="props.row.motivoDescontoParcela">
                                                <option v-for="item in motivosDescontoParcela"
                                                        :key="item.id"
                                                        :value="item.id">
                                                    {{ item.descricaoInterno }}
                                                </option>
                                            </b-select>
                                        </div>
                                    </div>
                                    <label class="label"
                                           v-if="(props.row.situacao !== 1 && props.row.situacao !== 3) || !isInRole('receber-parcela-alterar-valor')">{{ props.row.valor | currency }}
                                    </label>
                                </b-table-column>

                                <b-table-column field="" label="Espécie">
                                    <div>
                                        <div v-if="props.row.situacao === 2">
                                            <span class="help">
                                                Espécie:
                                                <b>
                                                    {{props.row.especieDeRecebimento.nome | especieRecebimento}}
                                                </b>
                                            </span>
                                        </div>
                                        <div v-else>
                                            <b-field label="">
                                                <b-select v-model="props.row.especieDeRecebimentoSelecionada"
                                                          @input="especieSelecionada(props.row)">
                                                    <option v-for="option in especies"
                                                            :value="option.id"
                                                            :key="option.id">
                                                        {{ option.nome }}
                                                    </option>
                                                </b-select>
                                            </b-field>
                                        </div>
                                    </div>
                                </b-table-column>

                                <b-table-column field="" label="Dados">
                                    <div v-if="props.row.situacao !== 2">
                                        <div class="panel"
                                             v-if="props.row.especieDeRecebimentoSelecionada == 2 || props.row.especieDeRecebimentoSelecionada == 3 ">
                                            <label class="label">Dados</label>
                                            <b-select placeholder="Banco"
                                                      v-model="props.row.chequeBanco">
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                            <input type="text"
                                                   placeholder="Agência"
                                                   v-model="props.row.chequeAgencia"
                                                   class="input" />
                                            <b-input type="text"
                                                     placeholder="Autorização"
                                                     v-model="props.row.autorizacao" />
                                            <b-select placeholder="Banco"
                                                      v-model="props.row.bandeira.id">
                                                <option v-for="option in bandeiras"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                        </div>
                                        <div class="panel"
                                             v-if="props.row.especieDeRecebimentoSelecionada == 4 ||props.row.especieDeRecebimentoSelecionada == 6 ">
                                            <label class="label">Dados</label>
                                            <b-select placeholder="Banco"
                                                      v-model="props.row.chequeBanco">
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                            <input type="text"
                                                   placeholder="Agência"
                                                   v-model="props.row.chequeAgencia"
                                                   class="input" />
                                            <b-input type="text"
                                                     placeholder="Número"
                                                     v-model="props.row.chequeNumero" />
                                        </div>
                                        <div class="panel"
                                             v-if="props.row.especieDeRecebimentoSelecionada == 5">
                                            <label class="label">Dados</label>
                                            <b-select placeholder="Banco"
                                                      v-model="props.row.chequeBanco">
                                                <option v-for="option in bancos"
                                                        :value="option.value"
                                                        :key="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </b-select>
                                            <input type="text"
                                                   placeholder="Agência"
                                                   v-model="props.row.chequeAgencia"
                                                   class="input" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="props.row.especieDeRecebimento != null && (props.row.Id != 0 || this.pagamentoGuia == null || !this.pagamentoGuia) && props.row.especieDeRecebimento.id !== 1 ">
                                            <p v-if="props.row.chequeBanco" class="help">
                                                Banco: <b>{{ props.row.chequeBanco }}</b>
                                            </p>
                                            <p v-if="props.row.chequeAgencia" class="help">
                                                Agência: <b>{{ props.row.chequeAgencia }}</b>
                                            </p>
                                            <p v-if="props.row.chequeNumero" class="help">
                                                Número: <b>{{ props.row.chequeNumero }}</b>
                                            </p>
                                            <p v-if="props.row.autorizacao" class="help">
                                                Autorização: <b>{{ props.row.autorizacao }}</b>
                                            </p>
                                            <p v-if="props.row.bandeira" class="help">
                                                Bandeira: <b>{{ props.row.bandeira.nome }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </b-table-column>

                                <b-table-column field="" label="Recebimento">
                                    <b-datetimepicker v-show="props.row.recebido"
                                                      v-model="props.row.dataDeRecebimento"
                                                      icon="calendar-today"
                                                      expanded>
                                    </b-datetimepicker>
                                </b-table-column>

                                <b-table-column field="" label="Boleto">
                                    <div v-if="permiteGeracao && props.row.situacao === 1">
                                        <b-field label="Controle Interno">
                                            <div>
                                                <b-input type="text"
                                                         v-model="props.row.nossoDocumento" />
                                                <p class="control">
                                                    <button type="button"
                                                            size="is-small"
                                                            class="button is-success"
                                                            v-if="isEmitido(props.row)"
                                                            @click.prevent.stop="regerarBoleto(props.row)">
                                                        Re-Gerar Boleto
                                                    </button>
                                                    <button type="button"
                                                            size="is-small"
                                                            class="button is-success"
                                                            v-if="!isEmitido(props.row)"
                                                            @click.prevent.stop="gerarBoleto(props.row)">
                                                        Gerar Boleto
                                                    </button>
                                                </p>
                                            </div>
                                        </b-field>
                                    </div>
                                </b-table-column>

                                <b-table-column field="" label="Situação">
                                    <template v-if=" model.quantidadeExames >= 0 &&
                                        model.convenio.tipoConvenio && ((model.convenio.tipoConvenio.faturaAberta && model.situacaoReceber == 1) || !model.convenio.tipoConvenio.faturaAberta) || !model.convenio.tipoConvenio">
                                        <div v-if=" props.row.situacao === 1 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-light" size="is-small">Pendente</b-button>
                                            <b-button type="button is-tag is-primary"
                                                      size="is-small"
                                                      @click="analisarParcela( props.row, props.row.situacao, props.row.index )">
                                                Analisar
                                            </b-button>

                                            <b-button type="button is-tag is-success"
                                                      size="is-small"
                                                      icon-left="check"
                                                      v-if="isInRole('receber-pagar-parcela')"
                                                      @click="receberParcela(props.row, props.row.situacao, props.row.index ) "></b-button>
                                            <b-button type="button is-tag is-danger"
                                                      size="is-small"
                                                      icon-left="close"
                                                      v-if=" isInRole('receber-cancelar-parcela') && (props.row.podeCancelar != null ? props.row.podeCancelar : true)" 
                                                      @click="cancelarParcela( props.row,props.row.situacao, props.row.index) "></b-button>
                                        </div>
                                        <div v-if=" props.row.situacao === 2 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-light" size="is-small">
                                                <span v-if="props.row.dataDeRecebimento" class="help">
                                                    Recebido em
                                                    {{ props.row.dataDeRecebimento | data }} por
                                                    <b v-if="props.row.funcionarioRecebimento">
                                                        {{props.row.funcionarioRecebimento.nome }}
                                                    </b>
                                                </span>
                                            </b-button>
                                            <div class="panel-heading">
                                                <b-button type="button is-tag is-danger"
                                                          size="is-small"
                                                          icon-left="close"
                                                          v-if="isInRole('receber-cancelar-parcela')"
                                                        @click="cancelarParcelaPaga( props.row,props.row.situacao, props.row.index) ">Cancelar</b-button>
                                                <b-button type="button is-primary"
                                                          size="is-small"
                                                          @click="imprimirRecibo(props.row)">Imprimir Recibo</b-button>
                                            </div>
                                        </div>
                                        <div v-if=" props.row.situacao === 3 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-danger" size="is-small">Cancelado</b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 4 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-danger" size="is-small">Pagamento Cancelado</b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 5 && !props.row.recebido && !props.row.pagamentoCancelado">
                                            <b-button type="button is-tag is-light" size="is-small">Liberado</b-button>
                                            <b-button type="button is-tag is-success"
                                                      size="is-small"
                                                      icon-left="check"
                                                      v-if="isInRole('receber-pagar-parcela')"
                                                      @click="receberParcela(props.row,props.row.situacao,props.row.index )"></b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 6 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <b-button type="button is-tag is-warning" size="is-small">Análise</b-button>
                                            <b-button type="button is-tag is-primary"
                                                      size="is-small"
                                                      @click="liberarParcela(props.row,props.row.situacao,props.row.index)"> Liberar
                                            </b-button>
                                            <b-button type="button is-tag is-danger"
                                                      size="is-small"
                                                      @click="rejeitarParcela( props.row, props.row.situacao, props.row.index ) "> Rejeitar
                                            </b-button>
                                        </div>
                                        <div v-if="props.row.situacao === 7 && !props.row.recebido && !props.row.pagamentoCancelado ">
                                            <div class="columns">
                                                <div class="column">
                                                    <b-button type="button is-tag is-danger"
                                                              size="is-small">Rejeitado</b-button>
                                                </div>
                                                <div class="column">
                                                    <b-select v-if="props.row.rejeitado"
                                                              required
                                                              placeholder="Selecione o Motivo"
                                                              class="is-fullwidth"
                                                              v-model="props.row.motivoRejeitado">
                                                        <option v-for="item in motivosRejeicao"
                                                                :key="item.id"
                                                                :value="item.id">
                                                            {{ item.descricaoInterno }}
                                                        </option>
                                                    </b-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="props.row.recebido">
                                            <b-button type="button is-tag is-success" size="is-small">Recebido</b-button>
                                        </div>
                                        <div v-if="props.row.pagamentoCancelado">
                                            <b-button type="button is-tag is-danger" size="is-small">Pagamento Cancelado</b-button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <b-button type="button is-light" size="is-small">Pendente</b-button>
                                    </template>
                                </b-table-column>

                                <b-table-column field="dados" label=" " v-if="props.row.id > 0">
                                    <modalDadosParcelaReceber :parcela="props.row"></modalDadosParcelaReceber>
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <div class="tile is-child notification is-warning"
                                     v-if="mostrarErroBoleto && erroCadastro.length">
                                    <span v-if="erroCadastro.length">*Não é possível gerar boletos</span>
                                    <ul class="">
                                        <li v-for="erro in erroCadastro" :key="erro" class="help">
                                            <span>{{ erro }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>
                <article v-if="model.quantidadeExames > 0" class="tile is-child box">
                    <div class="panel-heading has-text-centered button is-light fullwidth"
                         @click="listarExames()">
                        Lista de Exames
                    </div>
                    <b-collapse class="panel"
                                animation="slide"
                                :open.sync="isOpenExames"
                                expanded>
                        <b-table :data="model.guiaExames"
                                 striped
                                 hoverable
                                 class="fullwidth"
                                 expanded>
                            <template slot-scope="props">
                                <b-table-column field="" label="Guia">
                                    <router-link :to="{ name: 'guia', params: { id: props.row.guiaExame.guiaExameId.guiaId }, }">{{ props.row.guiaExame.guiaExameId.guiaId }}
                                    </router-link>
                                </b-table-column>
                                <b-table-column field="" label="Item">
                                    {{ props.row.guiaExame.item }}
                                </b-table-column>
                                <b-table-column field="" label="Exame">
                                    <b-button type="is-light">
                                        {{ props.row.guiaExame.exame.apelido }}
                                    </b-button>
                                </b-table-column>
                                <b-table-column field="" label="Situação">
                                    <div v-if="!props.row.removido">
                                        <b-button size="is-small"
                                                  :type="{ 'button is-light': props.row.situacao === 1, 'button is-success': props.row.situacao === 2, 'button is-danger': props.row.situacao === 3, }">{{ props.row.situacao | situacao }}</b-button>
                                        <b-button size="is-small"
                                                  type="button is-tag is-danger"
                                                  icon-left="close"
                                                  v-if="props.row.situacao === 1"
                                                  @click="glosarExame(props.row)"></b-button>
                                    </div>
                                    <div v-else>
                                        <b-button size="is-small" :type="'button is-light'">Removido</b-button>
                                    </div>
                                </b-table-column>
                                <b-table-column field="" label="Preço" numeric>
                                    <span style="text-decoration: line-through"
                                          v-if="props.row.situacao === 3">{{ props.row.guiaExame.preco | currency }}</span>
                                    <span v-else>{{ props.row.guiaExame.preco | currency }}</span>
                                </b-table-column>
                                <b-table-column field=""
                                                label=""
                                                v-if="model.situacaoReceber == 1 && !props.row.removido">
                                    <b-button icon-left="delete"
                                              size="is-small"
                                              type="is-danger"
                                              @click.prevent.stop="removerExame(props.row)"></b-button>
                                </b-table-column>
                            </template>
                            <template slot="footer">
                                <th>
                                    <b-button type="is-primary"
                                              @click="glosarGuia()"
                                              v-if="model.situacaoReceber == 2">
                                        Glosar guia
                                    </b-button>
                                    <b-button type="is-success"
                                              @click="incluirGuia()"
                                              v-if="model.situacaoReceber == 1">
                                        Incluir guia
                                    </b-button>
                                </th>
                                <th class="has-text-danger">
                                    <span v-if="valorTotalExamesGlosados > 0">Glosas:</span>
                                </th>
                                <th class="has-text-danger">
                                    <span v-if="valorTotalExamesGlosados > 0">
                                        {{ valorTotalExamesGlosados | currency }}
                                    </span>
                                </th>
                                <th>TOTAL:</th>
                                <th class="has-text-right">
                                    {{ valorTotalExamesSemGlosa | currency }}
                                </th>
                            </template>
                        </b-table>
                    </b-collapse>
                </article>

                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back()">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style scoped>
    .btn-action {
        margin: 0.2rem;
    }

    .label-parcela-cancelada {
        padding-top: 0.25rem;
    }

    .collapse-content {
        width: 100% !important;
    }

    .panel .control {
        max-width: 210px;
    }
</style>

<script>
    import { mapGetters, mapState } from "vuex";
    import titlebar from "@/components/titlebar.vue";
    import historicoAlteracoes from "@/components/historicoAlteracoes.vue";
    import modalDadosParcelaReceber from "@/components/financeiro/modalDadosParcelaReceber.vue";
    import searchIdName from "@/components/searchIdName.vue";
    import moment from "moment";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    versao: null,
                    convenio: {
                        id: 0,
                        tissVersao: null,
                    },
                    contaCorrente: {
                        id: 0,
                    },
                    paciente: { id: 0 },
                    funcionario: { id: 0 },
                    localDeOrigem: { id: 0 },
                    unidade: { id: 0 },
                    cancelada: false,
                    observacoes: null,
                },
                contas: [],
                salvandoDados: false,
                pagamentoGuia: null,
                permiteGeracao: false,
                isOpenParcelas: true,
                isOpenExames: false,
                totalExames: 0,
                qntdeParcelas: 0,
                erroCadastro: [],
                mostrarErroBoleto: true,
                especies: [],
                valorNaoBate: false,
                unidades: [],
                bancos: [],
                valorParcelado: false,
                valorTotalParcelas: 0,
                alterouParcelas: false,
                temNotaFiscal: false,
                isLoading: false,
                notaFiscalConsulta: false,
                notaFiscal: null,
                bandeiras: [],
                descontoConvenioGuia: 0,
                desconto: 0,
                motivosRejeicao: [],
                motivosVencimentoAlterado: [],
                motivosDescontoParcela: [],
                valorTotalExamesSemGlosa: 0,
                valorTotalExamesGlosados: 0,
            };
        },
        components: {
            titlebar,
            historicoAlteracoes,
            searchIdName,
            modalDadosParcelaReceber,
        },
        filters: {
            situacao: (valor) => {
                if (!valor) return null;

                switch (valor) {
                    case 1:
                        return "Aguardando";
                    case 2:
                        return "Faturado";
                    case 3:
                        return "Glosado";
                }
            },
            especieRecebimento: (valor) => {
                if (!valor) return null;

                switch (valor) {
                    case 1:
                        return "Dinheiro";
                    case 2:
                        return "Cartão de Crédito";
                    case 3:
                        return "Cartão de Débito";
                    case 4:
                        return "Chque";
                    case 5:
                        return "Transferência Bancária";
                    case 6:
                        return "Boleto";
                }
            },
        },
        created() {
            this.loadAsyncData();

        },
        computed: {
            titleStack() {
                return [
                    "Financeiro",
                    "Contas a Receber",
                    this.model.id > 0 ? this.model.id : "novo",
                ];
            },
            ...mapState(["unidadeId", "config", "integradores"]),
            ...mapGetters(["isInRole"]),
        },
        methods: {
            loadAsyncData() {
                if (
                    (this.$route.params.id || this.$route.params.guiaId) &&
                    (this.$route.params.id > 0 || this.$route.params.guiaId > 0)
                ) {
                    this.isLoading = true;
                    let url = "/api/financeiro/receber?id=" + this.$route.params.id;

                    if (this.$route.params.guiaId) {
                        //carregar guia particular
                        this.pagamentoGuia = true;
                        url =
                            "/api/financeiro/ReceberGuiaParticular?guiaId=" +
                            this.$route.params.guiaId +
                            "&condicaoPagamento=" +
                            this.$route.params.condicaoPagamento;
                    }

                    this.$http
                        .get(url)
                        .then((res) => {
                            if (res.data.listarDocumentosDaGuia) {
                                this.$router.push({
                                    name: "lista-contas-receber",
                                    query: {
                                        guiaId: this.$route.params.guiaId,
                                    },
                                });
                                return;
                            }

                            const model = res.data?.item;
                            model.data = model?.data
                                ? moment(model.data)?.toDate()
                                : new Date();
                            this.contas = res.data.contasCorrentes;
                            this.pagamentoGuia = res.data.pagamentoGuia;
                            this.especies = res.data.especies;
                            this.unidades = res.data.unidades;
                            this.bandeiras = res.data.bandeira;
                            this.descontoConvenioGuia = res.data.desconto;
                            this.desconto = res.data.descontoContaReceber != null ? res.data.descontoContaReceber : 0;
                            this.bancos = res.data.bancos;
                            this.totalExames = res.data.totalExames;
                            if (this.pagamentoGuia) {
                                this.valorTotalExamesSemGlosa = this.totalExames;
                            }

                            model.parcelas = model.parcelas?.map((p, index) => {
                                const valor = parseFloat(Math.round(p.valor * 100) / 100).toFixed(
                                    2
                                );
                                const valorReferencia = valor.toString();

                                const espRecebimento =
                                    p.especieDeRecebimento != null ? p.especieDeRecebimento.id : 0;

                                return {
                                    ...p,
                                    dataDeVencimento: new moment(p.dataDeVencimento)?.toDate(),
                                    dataDeVencimentoInicio: new moment(
                                        p.dataDeVencimento
                                    )?.toDate(),
                                    dataDeRecebimento:
                                        p.dataDeRecebimento != null
                                            ? new moment(p.dataDeRecebimento)?.toDate()
                                            : null,
                                    especieDeRecebimentoSelecionada: this.especies.filter(
                                        (x) => x.id == espRecebimento
                                    )[0]?.id,
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    valor: valor,
                                    valorReferencia: valorReferencia,
                                    bandeira: p.bandeira != null ? p.bandeira : this.bandeiras[0],
                                };
                            });

                            if (!model.convenio) {
                                model.convenio = { id: 0 };
                            }

                            this.model = model;
                            this.verificarErrosBoleto();
                            if (this.valorTotalParcelas == 0) {
                                this.model.parcelas
                                    ?.filter((x) => x.situacao !== 3)
                                    .forEach(
                                        (p) => (this.valorTotalParcelas += parseFloat(p.valor))
                                    );
                                this.valorTotalParcelas = this.valorTotalParcelas.toFixed(2);
                            }

                            if (this.model.notaFiscal_Id) {
                                this.temNotaFiscal = true;
                            } else {
                                this.temNotaFiscal = false;
                            }

                            this.qntdeParcelas = this.model.parcelas.filter((x) => x.situacao !== 2 && x.situacao !== 3).length
                           
                            this.isLoading = false;
                        })
                        .catch((ex) => {
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = true;
                    this.$http
                        .get("/api/financeiro/receber")
                        .then((res) => {
                            const novoItem = {};
                            novoItem.id = 0;
                            novoItem.data = new Date();
                            novoItem.funcionario = this.model.funcionario;
                            novoItem.notaFiscal = null;
                            novoItem.funcionario = res.data.item?.funcionario;

                            novoItem.unidade = { id: 0 };
                            novoItem.convenio = { id: 0, tissVersao: null };
                            novoItem.paciente = { id: 0 };
                            novoItem.localDeOrigem = { id: 0 };
                            novoItem.quantidadeExames = 0;
                            
                            this.contas = res.data.contasCorrentes;
                            this.pagamentoGuia = res.data.pagamentoGuia;
                            this.especies = res.data.especies;
                            this.unidades = res.data.unidades;
                            this.bandeiras = res.data.bandeira;
                            this.descontoConvenioGuia = res.data.desconto;
                            this.desconto = res.data.descontoContaReceber != null ? res.data.descontoContaReceber : 0;
                            this.bancos = res.data.bancos;

                            novoItem.contaCorrente = res.data.item?.contaCorrente;
                            novoItem.parcelas = [
                                {
                                    id: 0,
                                    situacao: 1,
                                    valor: 0.0,
                                    valorReferencia: 0.0,
                                    dataDeVencimento: new Date(),
                                    dataDeVencimentoInicio: new Date(),
                                    dataDeRecebimento: new Date(),
                                    especieDeRecebimentoSelecionada: this.especies[0]?.id,
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    bandeira: this.bandeiras[0],
                                },
                            ];
                            this.model = novoItem;

                            this.qntdeParcelas = this.model.parcelas.filter((x) => x.situacao !== 2 && x.situacao !== 3).length

                            this.isLoading = false;
                        })
                        .catch((e) => {
                            console.error(e);
                            this.isLoading = false;
                        });
                }

                this.$http.get("/api/search/motivosrejeicao").then((res) => {
                    this.motivosRejeicao = res.data;
                });

                this.$http.get("/api/search/motivosvencimentoalterado").then((res) => {
                    this.motivosVencimentoAlterado = res.data;
                });

                this.$http.get("/api/search/motivosdescontoparcela").then((res) => {
                    this.motivosDescontoParcela = res.data;
                });
            },
            validarValorTotalDasParcelas() {
                if (this.descontoConvenioGuia && this.descontoConvenioGuia > 0) {
                    this.desconto = this.descontoConvenioGuia;
                }

                this.valorNaoBate = false;
                if (this.valorTotalParcelas != null && this.alterouParcelas) {
                    let valorTotalParcelas = 0;
                    this.model.parcelas
                        ?.filter((x) => x.situacao !== 3)
                        .forEach((p) => {
                            valorTotalParcelas += parseFloat(p.valor);
                        });

                    if (
                        this.totalExames - (parseFloat(valorTotalParcelas) + parseFloat(this.desconto)) > 0.1
                    ) {
                        this.valorNaoBate = true;
                    }
                }

                return this.valorNaoBate;
            },
            onSubmit() {
                const isParcelaInvalida = this.validarValorTotalDasParcelas();
                this.$forceUpdate();
                if (!isParcelaInvalida) {
                    this.isLoading = true;

                    const newItem = this.getItem();

                    const listaParcelasCanceladasPagas = this.getListaParcelasCanceladasPagas();

                    const listaParcelasCanceladas = this.getListaParcelasCanceladas();

                    const alterouParcelas = this.alterouParcelas;

                    const pagamentoGuia = this.pagamentoGuia;

                    const desconto = this.desconto;

                    const cancelada = !this.model.cancelada ? false : this.model.cancelada;

                    const observacoes = ( this.model.observacoes !=null && this.model.observacoes !=undefined ) ?  this.model.observacoes : '';


                    if (this.model.id == 0 && !this.model.unidade.id) {
                        this.$buefy.dialog.alert({
                            title: "Unidade obrigatório",
                            message: "Especifique uma unidade para continuar.",
                            type: "is-warning",
                            hasIcon: true,
                        });
                        this.isLoading = false;
                        return;
                    }

                    if (
                        this.model.paciente &&
                        (this.model.paciente.id == 0 || this.model.paciente.id == null)
                    ) {
                        newItem.paciente = null;
                    }

                    this.$http
                        .post(
                            `/api/financeiro/receber?cancelando=${listaParcelasCanceladas}&cancelandoPagas=${listaParcelasCanceladasPagas}&alterouParcelas=${alterouParcelas}&pagamentoGuia=${pagamentoGuia}&desconto=${desconto}&cancelada=${cancelada}&observacoes=${observacoes}`,
                            newItem
                        )
                        .then(() => {
                            this.isLoading = false;
                            if (this.pagamentoGuia) {
                                this.$router.push({
                                    name: "workflow",
                                    params: {
                                        id: this.$route.params.guiaId,
                                    },
                                });
                            } else {
                                this.$router.push({ name: "financeiro" });
                            }
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            console.error(err);
                            if (err.body?.errors) {
                                Object.keys(err.body.errors).forEach((x) => {
                                    this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                });
                            } else {
                                const mensagem =
                                    err.status === 500
                                        ? "Foi encontrado um erro no servidor ao salvar o registro"
                                        : "Erro ao salvar registro";
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: mensagem,
                                    type: "is-danger",
                                    queue: false,
                                });
                            }
                        });
                }
            },
            download(itemName, extension = "pdf") {
                window.open(
                    `/api/financeiro/${itemName.toLowerCase()}?id=${this.model.id}`,
                    "_blank"
                );
            },
            fecharFatura() {
                this.$buefy.dialog.confirm({
                    title: "Fatura",
                    message:
                        "Deseja realmente fechar a fatura? Você não poderá fazer alterações após essa ação.",
                    confirmText: "Confirmar",
                    type: "is-info",
                    hasIcon: true,
                    cancelText: "Cancelar",
                    onConfirm: () => {
                        this.model.situacaoReceber = 2;
                    },
                });
            },
            dividirParcela() {
                this.$buefy.dialog.prompt({
                    message: `Deseja dividir a parcela em quantas vezes?`,
                    inputAttrs: {
                        type: "number",
                        placeholder: "",
                        value: 0,
                        max: 999,
                        min: 0,
                        title: "O campo precisa ser informado",
                    },
                    trapFocus: true,
                    confirmText: "OK",
                    cancelText: "Cancelar",
                    onConfirm: (value) => {
                        this.valorParcelado = true;
                        this.alterouParcelas = true;
                        //pegar a data da parcela
                        let dataParcela = moment().toDate();

                        if (this.descontoConvenioGuia && this.descontoConvenioGuia > 0) {
                            this.desconto = this.descontoConvenioGuia;
                        }

                        let valor = 0;
                        if (this.model.parcelas?.length) {
                            dataParcela = moment(
                                this.model.parcelas[0]?.dataDeVencimento
                            ).toDate();
                            if (this.totalExames && this.totalExames > 0) {
                                if(this.descontoConvenioGuia && this.descontoConvenioGuia > 0){
                                    valor = ((parseFloat(this.totalExames) - parseFloat(this.desconto)) / value).toFixed(2);
                                }else {
                                    valor = (parseFloat(this.totalExames) / value).toFixed(2);
                                }
                            } else {
                                valor = ((parseFloat(this.valorTotalParcelas) - parseFloat(this.desconto)) / value).toFixed(2);
                            }
                        }
                        if (value) {
                            this.model.parcelas = [];
                            const totalParcelas = value;
                            for (let p = 0; p < totalParcelas; p++) {
                                const dataDeVencimento = moment(dataParcela).add("M", p).toDate();
                                this.model.parcelas.push({
                                    id: 0,
                                    dataDeVencimento: dataDeVencimento,
                                    dataDeVencimentoInicio: dataDeVencimento,
                                    dataDeRecebimento: new Date(),
                                    recebido: false,
                                    pagamentoCancelado: false,
                                    situacao: 1,
                                    especieDeRecebimentoSelecionada: this.especies[0].id,
                                    valor: valor,
                                    podeCancelar: totalParcelas > 1,
                                });
                            }
                            this.qntdeParcelas = this.model.parcelas.length;
                        }
                    },
                });
            },
            cancelarFatura() {
                if (this.model.notaFiscal != null && this.model.notaFiscal_Id != null) {
                    this.$buefy.dialog.alert({
                        title: "Fatura",
                        message:
                            "Não é possível cancelar uma fatura com nota fiscal emitida!",
                        type: "is-warning",
                        hasIcon: true,
                    });
                } else {
                    this.isLoading = true;
                    this.$http
                        .get(`/api/financeiro/cancelaFaturamento?receberId=${this.model.id}`)
                        .then((res) => {
                            this.isLoading = false;
                            this.$router.push({ name: "financeiro" });
                        });
                }
            },
            isEmitido(row) {
                return row.situacaoBoleto & (1 == 1);
            },
            verificarErrosBoleto() {
                this.erroCadastro = [];
                this.mostrarErroBoleto = true;
                const temCPF = this.model.paciente == null || this.model.paciente?.cpf;
                const temCEP = this.model.paciente == null || this.model.paciente?.cep;
                const temCNPJ = this.model.contaCorrente?.cnpj;

                if (!temCPF) {
                    this.erroCadastro.push("O paciente precisa ter CPF cadastrado");
                }
                if (!temCNPJ) {
                    this.erroCadastro.push("A conta corrente deve ter CNPJ cadastrado");
                }
                if (!temCEP) {
                    this.erroCadastro.push("O paciente precisa ter CEP cadastrado");
                }
            },
            cancelarParcela(item, situacao, index) {
                item.parcelaCancelada = true;
                item.situacao = 3;

                if(item.parcelaCancelada && item.situacao == 3 && !item.recebido) {
                  this.qntdeParcelas = this.model.parcelas.filter((x) => !x.parcelaCancelada && !x.recebido).length;
                }

                this.recalcularParcelas(item, index);
            },
            receberParcela(item, situacao, index) {
                if (item.dataDeRecebimento == null) {
                    item.dataDeRecebimento = new Date();
                }
                item.recebido = true;

                 if(item.recebido && !item.parcelaCancelada) {
                  this.qntdeParcelas = this.model.parcelas.filter((x) => !x.parcelaCancelada && !x.recebido).length;
                }
            },
            liberarParcela(item, situacao, index) {
                item.situacao = 5;
                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoAnalise != null
                ) {
                    const date = new Date(item.dataDeVencimento);
                    date.setDate(
                        date.getDate() + this.model.convenio.tipoConvenio.prazoAnalise
                    );
                    item.dataDeVencimento = date;
                }
            },
            analisarParcela(item, situacao, index) {
                item.situacao = 6;
                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoPendente != null
                ) {
                    const date = new Date(item.dataDeVencimento);
                    date.setDate(
                        date.getDate() + this.model.convenio.tipoConvenio.prazoPendente
                    );
                    item.dataDeVencimento = date;
                }
            },
            dataDeVencimentoAlterada(item) {
                if (
                    item.dataDeVencimento.getTime() != item.dataDeVencimentoInicio.getTime()
                ) {
                    item.vencimentoAlterado = true;
                }
            },
            rejeitarParcela(item, situacao, index) {
                item.situacao = 7;
                item.rejeitado = true;

                Date.prototype.addDays = function (days) {
                    const date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                };

                if (
                    this.model.convenio != null &&
                    this.model.convenio.tipoConvenio != null &&
                    this.model.convenio.tipoConvenio.prazoPendente != null
                ) {
                    this.model.parcelas.push({
                        id: 0,
                        dataDeVencimento: new Date().addDays(
                            this.model.convenio.tipoConvenio.prazoPendente
                        ),
                        dataDeVencimentoInicio: new Date().addDays(
                            this.model.convenio.tipoConvenio.prazoPendente
                        ),
                        recebido: false,
                        pagamentoCancelado: false,
                        situacao: 1,
                        especieDeRecebimentoSelecionada: this.especies[0].id,
                        valor: item.valor,
                        podeCancelar: true,
                    });
                }
            },
            cancelarParcelaPaga(item, situacao, index) {
                item.pagamentoCancelado = true;
                item.situacao = 3;
                this.recalcularParcelas(item, index);
            },
            imprimirRecibo(item) {
                window.open(`/api/financeiro/GeraRecibo?parcelaId=${item.id}`, "_blank");
            },
            glosarExame(item) {
                item.situacao = 3;
            },
            exibirParcelarValor() {
                return (
                    !this.model.parcelas?.find(
                        (p) => p.situacao == 2 || p.situacao == 3 || p.situacao == 4
                    ) && this.isInRole("receber-parcela-alterar-reparcelar")
                );
            },
            recalcularParcelas(item, index, desconto = 0) {
                if (this.descontoConvenioGuia && this.descontoConvenioGuia > 0) {
                    this.desconto = this.descontoConvenioGuia;
                }

                let valorTotal = 0;
                if (!isNaN(this.totalExame)) {
                    valorTotal = parseFloat(this.totalExames);
                } else {
                    valorTotal = parseFloat(this.valorTotalParcelas);
                }

                //Quando for recalculo de alteração no valor de alguma parcela
                if (!item.parcelaCancelada) {
                    valorTotal -= parseFloat(item.valor);
                    this.model.parcelas
                        .filter((x) => !x.parcelaCancelada)
                        .forEach((p, i) => {
                            if (i != index) {
                                if (
                                    this.model.parcelas.filter((x) => x.situacao !== 3).length - 1 == 0
                                ) {
                                    p.valor = 0.0;
                                } else {
                                    p.valor = (
                                        valorTotal /
                                        (this.model.parcelas.filter((x) => x.situacao !== 3).length - 1)
                                    ).toFixed(2);
                                }
                            } else {
                                p.valor = parseFloat(p.valor).toFixed(2);
                            }
                        });
                    //Quando for recalculo de cancelamento de alguma parcela
                } else {
                    this.model.parcelas
                        .filter((x) => !x.parcelaCancelada)
                        .forEach((p, i) => {
                            p.valor = ( valorTotal / this.model.parcelas.filter((x) => x.situacao !== 3).length).toFixed(2);
                        });
                }
            },
            getItem() {
                const novoItem = {};

                novoItem.id = this.model.id;
                novoItem.data = this.model.data;
                novoItem.convenio = this.model.convenio?.id ? this.model.convenio : null;
                novoItem.paciente = this.model.paciente;
                novoItem.funcionario = this.model.funcionario;
                novoItem.localDeOrigem = this.model.localDeOrigem;
                novoItem.unidade = this.model.unidade;
                novoItem.notaFiscal = this.model.notaFiscal;
                novoItem.contaCorrente = this.model.contaCorrente;
                novoItem.situacaoReceber = this.model.situacaoReceber;

                novoItem.parcelas = this.model.parcelas?.map((p) => {
                    const item = {
                        ...p,
                    };
                    if (item.especieDeRecebimentoSelecionada) {
                        if (!item.especieDeRecebimento) {
                            item.especieDeRecebimento = {
                                id: item.especieDeRecebimentoSelecionada,
                            };
                        } else {
                            item.especieDeRecebimento.id = item.especieDeRecebimentoSelecionada;
                        }
                    }

                    if (item.recebido) {
                        item.situacao = 2;
                    }

                    return item;
                });

                novoItem.guiaExames = this.model.guiaExames?.map((g) => {
                    return {
                        ...g,
                    };
                });

                return novoItem;
            },
            getListaParcelasCanceladas() {
                return this.model.parcelas
                    ?.filter((p) => p.parcelaCancelada)
                    .map((p) => {
                        return p.id;
                    })
                    ?.join(",");
            },
            getListaParcelasCanceladasPagas() {
                return this.model.parcelas
                    ?.filter((p) => p.pagamentoCancelado)
                    .map((p) => {
                        return p.id;
                    })
                    ?.join(",");
            },
            pacienteAlterado(event) {
                this.model.paciente = {
                    id: event.data.id,
                    nome: event.data.nome,
                    sexo: event.data.sexo,
                };
            },
            gerarBoleto(item) {
                this.$http
                    .post(
                        `/api/financeiro/gerarBoleto?parelaId=${item.id}&nossoDocumento=${item.nossoDocumento}`
                    )
                    .then((res) => {
                        this.$router.push({ name: "financeiro" });
                    });
            },
            regerarBoleto(item) {
                this.$http
                    .post(
                        `/api/financeiro/gerarBoleto?parelaId=${item.id}&nossoDocumento=${item.nossoDocumento}`
                    )
                    .then((res) => {
                        this.$router.push({ name: "financeiro" });
                    });
            },
            somaParcelas() {
                let total = 0;
                this.model.parcelas?.forEach(
                    (p) => (total += parseFloat(p.valor.toString()))
                );
                return total;
            },
            labelId(id) {                                
                return (id>0) ? id : '' ;
            },            
            especieSelecionada(item) {
                if (!item.chequeBanco) {
                    item.chequeBanco = this.bancos[0]?.value;
                }
                if (
                    item.especieDeRecebimentoSelecionada == 2 ||
                    item.especieDeRecebimentoSelecionada == 3
                ) {
                    if (!item.bandeira) {
                        item.bandeira = {
                            id: this.bandeiras[0]?.value,
                            nome: this.bandeiras[0]?.text,
                        };
                    }
                }
                this.$forceUpdate();
            },
            aplicarDesconto() {
                this.$buefy.dialog.prompt({
                    message: `Desconto`,
                    inputAttrs: {
                        type: "text",
                        placeholder: "",
                        value: 0,
                        title: "O campo precisa ser informado",
                    },
                    trapFocus: true,
                    confirmText: "OK",
                    cancelText: "Cancelar",
                    onConfirm: (value) => {
                        this.desconto = value;

                        if (this.desconto != null) {
                            const porcentagem = this.desconto.indexOf("%") != -1;
                            if (porcentagem) {
                                this.desconto = this.desconto.replace("%", "").replace(",", ".");
                                this.desconto = this.formatPrice(this.desconto);
                                let total = 0;
                                this.model.parcelas?.filter((x) => x.situacao !== 3 && !x.recebido).forEach((p) => {
                                    if (
                                        p.situacao != 2 &&
                                        p.situacao != 3 &&
                                        p.situacao != 4 &&
                                        p.situacao != 7
                                    ) {
                                        p.valor = parseFloat(p.valor) - parseFloat(p.valor) * (parseFloat(this.desconto) / 100).toFixed(2);
                                        total += parseFloat(p.valor);
                                        p.descontoAplicado = true;
                                    }
                                });
                                this.valorTotalParcelas = total;
                                this.totalExames = total;
                            } else {
                                this.desconto = this.desconto.replace(",", ".");
                                this.valorTotalParcelas = parseFloat(this.valorTotalParcelas) - parseFloat(this.desconto);
                                this.totalExames = parseFloat(this.totalExames) - parseFloat(this.desconto);
                                
                                this.model.parcelas?.filter((x) => x.situacao !== 3 && !x.recebido).forEach((p) => {
                                    if (
                                        p.situacao != 2 &&
                                        p.situacao != 3 &&
                                        p.situacao != 4 &&
                                        p.situacao != 7
                                    ) {
                                        p.valor = (parseFloat(p.valor) - parseFloat(this.desconto) / this.model.parcelas.filter((x) => x.situacao !== 3 && !x.recebido).length).toFixed(2);
                                        p.descontoAplicado = true;
                                        return false;
                                    }
                                });
                            }
                        }
                        this.$forceUpdate();
                    },
                });
            },
            formatPrice(value) {
                const val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            emitirNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(`/api/financeiro/emitir?receberId=${this.model.id}`)
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.error.message;
                            if (response.body.error.data && response.body.error.data.fields) {
                                this.fieldErrors = Object.entries(
                                    response.body.error.data.fields
                                );
                            }
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Erro ao emitir a nota fiscal",
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.documentos = response.body;
                            this.loadAsyncData();
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao emitir nota fiscal"
                                    : "Erro ao emitir nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            pdfNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/pdf?documentoId=${this.model.notaFiscal_Id}&clienteId=${this.model.convenio.id}&receberId=${this.model.id}`,
                        { responseType: "arraybuffer" }
                    )
                    .then((response) => {
                        this.isLoading = false;
                        const file = new Blob([response.data], { type: "application/pdf" });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao abrir pdf nota fiscal"
                                    : "Erro ao abrir pdf nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            cancelarNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/cancelar?documentoId=${this.model.notaFiscal_Id}&receberId=${this.model.id}`
                    )
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.message;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Erro ao cancelar a nota fiscal",
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.documentoCancelado = response.body;
                            this.loadAsyncData();
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao cancelar nota fiscal"
                                    : "Erro ao cancelar nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            consultarNotaFiscal() {
                this.isLoading = true;
                this.$http
                    .get(
                        `/api/financeiro/consultar?documentoId=${this.model.notaFiscal_Id}&clienteId=${this.model.convenio.id}&receberId=${this.model.id}`
                    )
                    .then((response) => {
                        this.isLoading = false;
                        if (response.body.error) {
                            this.erro = response.body.error.message;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Erro ao consultar a nota fiscal",
                                type: "is-danger",
                                queue: false,
                            });
                        } else {
                            this.notaFiscalConsulta = true;
                            this.notaFiscal = response.body[0];
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        console.error(err);
                        if (err.body?.errors) {
                            Object.keys(err.body.errors).forEach((x) => {
                                this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                            });
                        } else {
                            const mensagem =
                                err.status === 500
                                    ? "Foi encontrado um erro no servidor ao consultar nota fiscal"
                                    : "Erro ao consultar nota fiscal";
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: mensagem,
                                type: "is-danger",
                                queue: false,
                            });
                        }
                    });
            },
            glosarGuia() {
                this.$buefy.dialog.prompt({
                    title: "Guia",
                    message: "Confirme a guia que deseja glosar.",
                    inputAttrs: {
                        type: "number",
                        placeholder: "Guia",
                    },
                    confirmText: "Confirmar",
                    cancelText: "Cancelar",
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.model.guiaExames.forEach((x) => {
                            if (x.guiaExame.guia.id == res) {
                                this.glosarExame(x);
                            }
                        });
                    },
                });
            },
            listarExames() {
                //this.totalExames=0.0;
                if (this.model.guiaExames == null || this.model.guiaExames.length == 0) {
                    this.isLoading = true;
                    this.$http
                        .get(`/api/financeiro/ReceberGuiaExames?receberId=${this.model.id}`)
                        .then((response) => {
                            this.isLoading = false;
                            this.model.guiaExames = response.body;

                            this.model.guiaExames?.forEach((g) => {
                                //this.totalExames += g.guiaExame?.preco
                                if (g.situacao == 3)
                                    this.valorTotalExamesGlosados += g.guiaExame?.preco;
                                else this.valorTotalExamesSemGlosa += g.guiaExame?.preco;
                            });

                            this.isOpenExames = !this.isOpenExames;
                            this.$forceUpdate();
                        })
                        .catch((err) => {
                            this.isLoading = false;
                            if (err.body?.errors) {
                                Object.keys(err.body.errors).forEach((x) => {
                                    this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                });
                            }
                        });
                } else {
                    this.isOpenExames = !this.isOpenExames;
                    this.$forceUpdate();
                }
            },
            removerExame(exame) {
                exame.removido = true;
            },
            incluirGuia() {
                this.$buefy.dialog.prompt({
                    title: "Guia",
                    message: "Confirme a guia que deseja incluir.",
                    inputAttrs: {
                        type: "number",
                        placeholder: "Guia",
                    },
                    confirmText: "Confirmar",
                    cancelText: "Cancelar",
                    trapFocus: true,
                    onConfirm: (res) => {
                        //faz ajax para buscar os exames não faturados e não cancelados dessa guia
                        this.isLoading = true;
                        this.$http
                            .get(`/api/financeiro/ReceberGuiaExamesPendentes?guiaId=${res}`)
                            .then((response) => {
                                this.isLoading = false;
                                const exames = response.body;

                                exames.forEach((exame) => {
                                    this.model.guiaExames.push({
                                        id: 0,
                                        guiaExame: exame,
                                        situacao: 1,
                                        incluido: true,
                                        removido: false,
                                    });
                                });
                            })
                            .catch((err) => {
                                this.isLoading = false;
                                if (err.body?.errors) {
                                    Object.keys(err.body.errors).forEach((x) => {
                                        this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                                    });
                                }
                            });
                    },
                });
            },
        },
    };
</script>
