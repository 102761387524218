<template>
    <section style="overflow: hidden;">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="model.nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <buscaCepEndereco :cep.sync="model.cep"
                                              :endereco.sync="model.endereco"
                                              :bairro.sync="model.bairro"
                                              :cidadeid.sync="model.cidadeId"
                                              :cidadenome.sync="model.cidadeNome"></buscaCepEndereco>

                            <div class="columns">
                                <div class="column">
                                    <b-field label="Fone">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="Celular">
                                        <b-input expanded
                                                 v-model="model.celular" 
                                                 v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <ValidationProvider rules="required" name="sexo" v-slot="{ errors, valid }">
                                        <b-field label="Sexo"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select placeholder="Selecione o sexo" v-model="model.sexo">
                                                <option value="F">Feminino</option>
                                                <option value="M">Masculino</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="data de nascimento" v-slot="{ errors, valid }">
                                        <b-field label="Nascimento"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker v-model="model.dataDeNascimento" trap-focus editable :max-date="new Date()" 
                                                :min-date="new Date(new Date().getFullYear() - 200, new Date().getMonth(), new Date().getDate())"></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <div class="field">
                                        <label class="label">Idade</label>
                                        <div class="control">
                                            <input class="input" type="text" @change="alterouIdade" :value="idade">
                                        </div>
                                    </div>
                                </div>
                                <div v-show="config.laboratorioVeterinario" class="column is-half-mobile texto-labels">
                                    <label class="label">Calcular idade em</label>
                                    <b-select v-model="model.unidadeIdade">
                                        <option value="A">Anos</option>
                                        <option value="M">Meses</option>
                                        <option value="D">Dias</option>
                                    </b-select>
                                </div>
                                <div class="column is-half-mobile texto-labels">
                                    <div class="field">
                                        <label class="label">Peso (kg)</label>
                                        <div class="control">
                                            <input class="input" type="number" v-model="model.peso">
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-half-mobile texto-labels">
                                    <div class="field">
                                        <label class="label">Altura (cm)</label>
                                        <div class="control">
                                            <input class="input" type="number" v-model="model.altura">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field label="Email"
                                                 ref="email"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="email" v-model="model.email" @blur="verificarEmail" :readonly="model.possuiUsuario"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">RG</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.rg" v-mask="'###############'">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">CPF</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cpf" v-mask="'###.###.###-##'">
                                        </div>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Cartão nacional de saúde</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cartaoNacionalDeSaude">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns is-multiline is-mobile">
                                <div class="column is-three-quarters-mobile">
                                    <div class="field">
                                        <label class="label">Inscrição</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.inscricao">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Nome da mãe</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.nomeDaMae">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-show="config.laboratorioVeterinario && !retornarResponsavel"><searchIdName :id.sync="model.racaId" @item="racaEspecie" label="Raça" table="Raca"></searchIdName></div>
                            <div v-show="config.laboratorioVeterinario && !retornarResponsavel"><searchIdName :id.sync="model.especieId" label="Espécie" table="Especie"></searchIdName></div>                            

                            <searchIdName v-show="config.laboratorioVeterinario && !retornarResponsavel" :id.sync="model.responsavelId" label="Responsável" table="Paciente">
                                <slot>
                                     <b-button icon-left="plus-thick"
                                               tag="router-link"
                                               target="_blank"                                              
                                              :to="{ name: 'portalconveniopaciente', query: {  retornarResponsavel:true }}"
                                               title="Cadastrar Responsável"></b-button>                                        
                                </slot>
                            </searchIdName>                            
                          

                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Observações</label>
                                        <div class="control">
                                            <textarea class="textarea" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </article>
                    </div>
                    <div class="tile is-parent is-3">
                        <article class="tile is-child is-12">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Usuário
                                </div>
                                <div v-if="!capturando">                                    
                                    <div class="panel-block">
                                        <figure class="image">
                                            <img :src="model.foto" ref="foto">
                                        </figure>
                                    </div>
                                </div>
                                <div v-else>                                    
                                    <div class="panel-block">
                                        <vue-web-cam ref="webcam"
                                                     :device-id="deviceId"
                                                     width="100%"
                                                     @cameras="onCameras" />
                                    </div>
                                    <div class="panel-block">
                                        <div class="field">
                                            <div class="select">
                                                <select v-model="camera">
                                                    <option>-- Select Device --</option>
                                                    <option v-for="device in devices"
                                                            :key="device.deviceId"
                                                            :value="device.deviceId">
                                                        {{ device.label }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-block tile is-12">                                    
                                        <b-button :class="capturando ? 'is-success  ' : 'is-link  '" 
                                                icon-left="camera"
                                                @click="onCapture" title="Clique para capturar pela webcam"
                                                expanded>
                                                Câmera
                                        </b-button>

                                        <div class="file has-name tile is-6">                                            
                                            <label class="file-label tile is-6" style="width: 100%;">
                                                <input class="file-input" type="file" name="foto" ref="fileSele" @change="imageFileSelected">
                                                <div class="file-cta tile is-12" style="">            
                                                    <span class="file-label" >
                                                        <i class="mdi mdi-file"></i> Arquivo
                                                    </span>
                                                </div>  
                                            </label>                                          
                                        </div>                                                                         
                                                                                                           
                                </div>
  
                                <div class="panel-block">
                                    <div class="field full-width">
                                        <b-switch v-model="model.criaUsuario" v-if="!model.possuiUsuario">
                                            Criar usuário web
                                        </b-switch>
                                        <b-button v-if="model.possuiUsuario"
                                                  class="button is-danger "
                                                  @click="deletarUsuarioPaciente(model.id)"
                                                  expanded>
                                            Cancelar usuário web
                                        </b-button>

                                    </div>
                                </div>
                            </article>
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    Indicação
                                </div>
                                <div class="panel-block">
                                    <b-field>
                                        <b-select v-model="model.indicacao">
                                            <option v-for="opcaoIndicacao in opcoesIndicacao"
                                                    :value="opcaoIndicacao"
                                                    :key="opcaoIndicacao">
                                                {{ opcaoIndicacao }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </article>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: this.$route.params.nome ?? null,
                    sexo: null,
                    dataDeNascimento: null,
                    cep: null,
                    endereco: null,
                    bairro: null,
                    cidadeId: null,
                    peso: null,
                    altura: null,

                    inscricao: null,
                    inscricaodv: null,
                    validade: null,
                    plano: null,
                    nomeDoTitular: null,
                    rg: null,
                    cpf: null,
                    cartaoNacionalDeSaude: null,
                    convenioPadraoId: null,
                    racaId: null,
                    racaEspecieId: null,
                    especieId: null,
                    email: null,
                    nomedamae: null,
                    responsavelId: null,
                    observacoes: null,

                    codigosIntegrador: [],

                    criaUsuario: false,
                    indicacao: null,
                    publicaResultadosNaInternet: false,

                    foto: null,
                    unidadeDeCadastroId: this.unidadeId,
                    unidadeIdade: null,
                                                        
                },
                retornarResponsavel: Boolean(this.$route.query.retornarResponsavel) ?? false,   
                capturando: false,
                camera: null,
                deviceId: null,
                devices: [],
                carregando: true,
                salvandoDados: false,
                fotoAlterada: false,

                arquivosCarregados: false,

                opcoesIndicacao: [
                    'Google',
                    'Facebook',
                    'Instagram',
                    'Panfleto',
                    'Revista',
                    'Médico',
                    'Amigo',
                    'Outros'
                ]
            };
        },
        components: {
            buscaCepEndereco,
              searchIdName,
        },
        created() {
            if (this.$route.params.id && this.$route.params.id>0) {
                this.$http.get('/api/portal/paciente?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if(this.model.dataDeNascimento){
                            this.model.dataDeNascimento = new Date(this.model.dataDeNascimento)
                        }
                        if (this.model.validade) {
                            this.model.validade = new Date(this.model.validade)
                        }
                        if (this.model?.foto){
                            if (this.model.foto.indexOf('data:image/') < 0){
                                this.model.foto = ` data:image/*;charset=utf-8;base64,${this.model.foto}`;
                            }
                        }
                        this.model.criaUsuario = this.model.usuario != null

                        this.carregando = false
                    })
            }
            else {
                this.carregando = false;
            }
            this.model.publicaResultadosNaInternet = this.config.resultadoNaInternetAuto;
            this.model.criaUsuario = this.config.resultadoNaInternetAuto;

            document.addEventListener('visibilitychange', this.verificaRetornarResponsavel, false); 
            
        },
        computed: {
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }

                return this.calcularIdade();
            },
            device() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            },
            ...mapState([
                'unidadeId',
                'config'
            ])
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                // Once we have a list select the first one
                const [first] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        },
        methods: {
            racaEspecie(item){
                if(item != null && item.data != null && item.data.especieId != null){
                    this.racaEspecieId = item.data.especieId;
                }
            },
            verificaRetornarResponsavel() {                               
                if(localStorage.retornarResponsavel > 0 && !this.retornarResponsavel){                                 
                    this.model.responsavelId = localStorage.retornarResponsavel;
                    localStorage.retornarResponsavel = null;
                }                
            },             
            calcularIdade(){
                if(this.model.dataDeNascimento > new Date()){
                    this.model.dataDeNascimento = null;
                    this.idade = null;

                    this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Valor informado para a data de nascimento inválido!',
                            type: 'is-danger',
                            queue: false
                        })

                    return;
                }

                const today = new Date();
                let m = today.getMonth() - this.model.dataDeNascimento.getMonth();
                                
                const dateAge = new Date(Date.now() - this.model.dataDeNascimento.getTime()); 
                const age = Math.abs(dateAge.getUTCFullYear() - 1970);
                                
                if(age > 200){
                    this.model.dataDeNascimento = null;
                    this.idade = null;

                    this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Valor informado para a data de nascimento inválido!',
                            type: 'is-danger',
                            queue: false
                        })

                    return;
                }

                if (age >= 1) {
                    if (age == 1) {
                        return ~~(age) + " ano";
                    } else {
                        return ~~(age) + " anos";
                    }
                } else {
                    if ((age == 0) && (m < 0)) {
                        m += 12;
                    }

                    if (today.getDate() < this.model.dataDeNascimento.getDate()) {
                        m--;
                    }

                    if (m > 0) {
                        if (m == 1) {
                            return ~~(m) + " mês";
                        } else {
                            return ~~(m) + " meses";
                        }
                    } else {
                        const d = (new Date(today.getFullYear(), today.getMonth(), today.getDate()) - this.model.dataDeNascimento) / 86400000;

                        if (d > 0) {
                            if (d == 1) {
                                return ~~(d) + " dia";
                            } else {
                                return ~~(d) + " dias";
                            }
                        } else {
                            return "";
                        }
                    }
                }
            },
            alterouIdade(event) {
                const id = event.target.value.replace(",", ".");

                if (id < 1) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Valor informado para a idade inválido!',
                        type: 'is-danger',
                        queue: false
                    })

                    return;
                }

                if (this.model.unidadeIdade == "D") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else if (this.model.unidadeIdade == "M") {
                    const dataNascimento = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    const quantidade = (id * 31 * 24 * 60 * 60 * 1000);
                    this.model.dataDeNascimento = new Date(dataNascimento.valueOf() - quantidade);
                }
                else {
                    this.model.dataDeNascimento = new Date(new Date().getFullYear() - id, new Date().getMonth(), new Date().getDate());
                }
            },
            verificarEmail() {
                this.$http.get(`/api/manutencao/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'paciente' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'O e-mail ' + res.data.email + ' já está sendo usado pelo usuario do ' + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            imageFileSelected: function(e) {
                const { name, type, size } = e.target.files[0];
                let imageData = null;
                const ref = this.$refs.foto;
                if (FileReader && size) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        imageData = fr.result;
                        this.model.foto = imageData;
                        this.fotoAlterada = true;
                        ref.src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }
            },
            onSubmit() {

                if(this.config.laboratorioVeterinario && !this.retornarResponsavel){

                    //raça obrigatório e responsável obrigatório                    
                    if(this.model.racaId == 0 || this.model.racaId == null || this.model.racaId == '' || this.model.racaId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Raça obrigatória.',
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
               
                    if(this.model.especieId == 0 || this.model.especieId == null || this.model.especieId == '' || this.model.especieId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Espécie obrigatória.',
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }else {

                        let racaEspecieCompativel = true;

                        if(this.racaEspecieId !== this.model.especieId){
                            racaEspecieCompativel = false;
                        }
      
                        if(!racaEspecieCompativel){

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Especie inválida para esta raça.',
                                type: 'is-danger',
                                queue: false
                            });                               
                            return;
                        }                                                             
                    }

                    if(this.model.responsavelId == 0 || this.model.responsavelId == null || this.model.responsavelId == '' || this.model.responsavelId == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Responsável obrigatório.',
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }                    
                    
                }

                if(this.config.cpfObrigatorio && !this.config.laboratorioVeterinario){
                    if(this.model.cpf == 0 || this.model.cpf == null || this.model.cpf == '' || this.model.cpf == undefined){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'CPF obrigatório.',
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
                }
                
                if(this.model.criaUsuario && this.model.email == null && !this.config.permiteCriarUsuarioParaPacienteSemEmail){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Não é possível criar usuário web sem email.',
                        type: 'is-danger',
                        queue: false
                    });
                    this.$refs.email.focus()
                    return;
                }
                
                if(this.config.cpfUnico && this.model.cpf != 0 && this.model.cpf != null && this.model.cpf != '' && this.model.cpf != undefined){
                    this.salvandoDados = true;

                    if(!this.model.id){
                        this.model.id = 0;
                    }

                    this.$http.get(`/api/manutencao/CheckCPF?cpf=${this.model.cpf}&id=${this.model.id}`)
                        .then(res => {
                            this.salvandoDados = false;
                            if(res.data > 0){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: 'Este CPF já está em uso.',
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                            else {
                                this.salvarPaciente();
                            }
                        })
                        .catch(err => {
                            this.salvandoDados = false;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Erro ao salvar paciente, verificando cpf: ' + err.body.title,
                                type: 'is-danger',
                                queue: false
                            })
                        })
                }
                else {
                    this.salvarPaciente();
                }

            },
            salvarPaciente(){
                this.salvandoDados = true;

                this.$http.post('/api/portal/paciente', this.model)
                    .then((res) => {

                        if(this.retornarResponsavel) localStorage.retornarResponsavel = parseInt(res.data);                        

                         if(this.retornarResponsavel){
                             window.close();
                         }                         

                        this.$router.push({
                            name: 'portalconveniopacienteguia', params: {
                                pacienteId: res.data
                            }
                        })

                        this.salvandoDados = false;
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err);
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar paciente: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            onCapture() {
                if (this.capturando) {
                    this.model.foto = this.$refs.webcam.capture();
                    setTimeout(() => {
                        this.$refs.webcam.stop();
                        this.capturando = false;
                    }, 500);
                } else {
                    this.capturando = true;
                    setTimeout(() => { this.$refs.webcam.start() }, 500);
                }
            },
            onCameras(cameras) {
                this.devices = cameras;
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;
            },
            deletarUsuarioPaciente(id) {
                this.$http.get(`/api/portal/deletarUsuarioPaciente?pacienteId=${id}`).then(d => {
                    this.$router.back()
                })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar paciente: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })

            }
        }
    }
</script>